import type { NextPage } from "next";
import Router from "next/router";

import DesktopErrorPage from "./DesktopErrorPage";
import MobileErrorPage from "./MobileErrorPage";

interface Props {
  statusCode?: number;
}

const Error: NextPage<Props> = (props) =>
  /^\/m\//.test(Router.asPath) ? (
    <MobileErrorPage {...props} />
  ) : (
    <DesktopErrorPage {...props} />
  );

export default Error;
