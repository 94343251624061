import GoBackIconButton from "@components/m/GoBackIconButton";
import Mobile from "@layout/mobile";
import { Button, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Image from "next/image";
import Router from "next/router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const Page404 = () => {
  const intl = useIntl();
  return (
    <>
      <div className="relative h-[223px] w-[292px]">
        <Image
          src={"/images/img-404.png"}
          alt="page_not_found_404"
          fill
          priority
          sizes="33vw"
        />
      </div>
      <div className="pt-10 text-xl text-gray300">
        <FormattedMessage id="Page Not Found" />
      </div>
      <div className="py-4 text-center text-body02 text-gray300">
        <div>
          <FormattedMessage id="HTTP ERROR 404" />
        </div>
        <FormattedMessage id="The page you are looking for doesn't exist or another error occurred. Try to visit Academy and explore more." />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => Router.push("/m/explore")}>
        {intl.formatMessage({ id: "EXPLORE" })}
      </Button>
    </>
  );
};

const MobileErrorPage = (props) => {
  const { statusCode } = props;
  const intl = useIntl();

  return (
    <Mobile
      {...props}
      headerTitle={intl.formatMessage({
        id: "common.header.explore",
        defaultMessage: "Explore",
      })}
      LeftComponent={<GoBackIconButton />}
      RightComponent={
        <div className="justify-center self-center justify-self-end">
          <IconButton>
            <SearchIcon />
          </IconButton>
        </div>
      }>
      <div className="flex h-full flex-col items-center justify-center">
        {statusCode === 404 ? <Page404 /> : null}
      </div>
    </Mobile>
  );
};

export default MobileErrorPage;
