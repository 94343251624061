import { scm } from "@components/AppEnv";
import { Provider, useAppContext } from "@components/context";
import Button from "@material-ui/core/Button";
import { StylesProvider } from "@material-ui/core/styles";
import Footer from "@page-components/Footer";
import Header from "@page-components/Header";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";

import S from "./ErrorPage.module.scss";

const contactEmail = scm?.contactUs?.email;

const ErrorPage = (props) => {
  const router = useRouter();
  const baseContext = useAppContext();
  const errorContext = {
    ...baseContext,
  };

  const { statusCode } = props;

  return (
    <Provider value={errorContext}>
      <Header {...props} />
      <div className={S.container}>
        <div className={S.error_main_wrapper}>
          <div className={S.error_main_wrapper_inner}>
            <div className="relative h-[240px] w-[320px]">
              <Image
                src={"/images/img-404.png"}
                alt="page_not_found_404"
                sizes="(max-width: 1350px) 50vw, 100vw"
                priority
                fill
              />
            </div>
            <div className={S.content}>
              <div className={S.title}>
                {statusCode === 404 ? (
                  <FormattedMessage
                    id="common.error.pageNotFound"
                    defaultMessage="PAGE NOT FOUND"
                  />
                ) : (
                  `An error ${statusCode} occurred on server`
                )}
              </div>
              <div className={S.text}>
                {statusCode === 404 ? (
                  <FormattedMessage id="The page you are looking for doesn't exist or another error occurred. Try to visit Academy and explore more." />
                ) : (
                  `If you need help please contact ${contactEmail}`
                )}
              </div>
            </div>
            <div className={S.button_box}>
              <StylesProvider injectFirst>
                <Button
                  className="btn-cta"
                  onClick={() => router.push("/explore")}>
                  <FormattedMessage
                    id="common.error.exploreButton"
                    defaultMessage="EXPLORE"
                  />
                </Button>
              </StylesProvider>
            </div>
          </div>
        </div>
      </div>
      <Footer {...props} />
    </Provider>
  );
};

export default ErrorPage;
